
import {computed, defineComponent, onMounted, ref} from "vue";
import {useTimerStore} from "@/store/timer.store";
import {ClientSelect, LoadPanel, PinaFilterObjects, TagSelect, UserSelect} from "@/core/composite/composite";
import Entities from "@/components/base/common/Entities.vue";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import Duration from "@/views/timer/Duration.vue";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import BaseMultiSelect from "@/components/base/select/BaseMultiSelect.vue";

export default defineComponent({
  name: "List",
  components: {BaseMultiSelect, BaseSelect, Duration, DateTimeFormat, KtDatatable, Entities},
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams("Timers",
        [
          {link: false, router: '', text: 'Timers'}
        ]
      )
    })
    const filterObjects = ref({userId: '', date: 'THIS_WEEK', clientId: '', tagIds: ''})
    const tagEntities = ref([])
    const store = useTimerStore();
    const page = computed(() => store.page);
    const headers = [
      {
        name: 'Entry Time',
        key: 'name',
        sortable: false
      },
      {
        name: 'User',
        key: 'user',
        sortable: false
      },
      {
        name: 'Start Time',
        key: 'startTime',
        sortable: false
      },
      {
        name: 'End Time',
        key: 'endTime',
        sortable: false
      },
      {
        name: 'Duration',
        key: 'duration',
        sortable: false,
      },
    ]
    return {
      filterObjects,
      store,
      page,
      ...PinaFilterObjects(store, filterObjects.value, ['user']),
      headers,
      ...LoadPanel(),
      ...UserSelect(),
      ...ClientSelect(),
      tagEntities,
      ...TagSelect(),
    }
  },
  methods: {
    searchEvent() {
      this.filterObjects.tagIds = this.tagEntities.join(",")
      this.updateFilterObject(this.filterObjects);
    },
    discardEvent() {
      this.filterObjects.date = 'THIS_WEEK'
      this.filterObjects.userId = ''
      this.filterObjects.clientId = ''
      this.updateFilterObject(this.filterObjects);
    },
  }
})
